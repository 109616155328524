<template>
    <v-row style="margin-top: 15vh">
        <v-col cols="12" class="text-center">
            <lottie-player
                src="https://assets7.lottiefiles.com/private_files/lf30_3k86c76a.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px; margin: auto;"
                loop
                autoplay
            ></lottie-player>
            <h2>Aan het versturen... ({{ seconds }}s)</h2>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            seconds: 0,
            interval: null
        };
    },
    mounted() {
        this.interval = setInterval(() => {
            this.seconds++;
        }, 1000);
    },
    destroyed() {
        clearInterval(this.interval);
    }
};
</script>
